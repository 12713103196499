import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
    name:"user",
    initialState:{
        currentUser:null,
        accesstoken:null,
        refreshtoken:null,
        isFetching:false,
        isLoggedIn:false,
        error:false
    },
    reducers:{
        LoginStart:(state) => {
            state.isFetching=true;
            state.isLoggedIn=false;
        },
        LoginSuccess:(state,action) => {
            
            state.isFetching=false;
            state.currentUser=action.payload.user;
            state.accesstoken=action.payload.accesstoken;
            state.refreshtoken=action.payload.refreshtoken;
            state.isLoggedIn=true;
        },
        LoginFailed:(state) => {
            state.isFetching=false;
            state.error=true;
            state.isLoggedIn=false;
        },
        UpdatePasswordSuccess: (state, action) => {
           
            state.currentUser = action.payload;
            
        }, 
        UpdateTokenSuccess: (state, action) => {
         
            state.accesstoken = action.payload.accesstoken;
            state.refreshtoken = action.payload.refreshtoken;
        },
        LoginReset:(state) => {
            state.isFetching=false;
            state.error=false;
            state.currentUser=null;
            state.accesstoken=null;
            state.refreshtoken=null;
            state.isLoggedIn=false;
        },
        
    },
});

export const {LoginStart, LoginSuccess, LoginFailed, LoginReset, UpdatePasswordSuccess, UpdateTokenSuccess} = userSlice.actions;

export default userSlice.reducer;
