export const sliderItems = [
    
    {
      id: 1,
      img: "https://i.ibb.co/T8rmJhn/393854679-818890790034628-922342517260705654-n-1.jpg",
      title: "+41 79 211 76 66",
      desc: "taximed.bern@gmail.com",
      bg: "ddd1c3",
    },
    {
      id: 2,
      img: "https://i.ibb.co/mCGrtXn/403606933-1019780059278306-7445582507849185738-n.jpg",
      title: "+41 79 211 76 66",
      
      desc: "taximed.bern@gmail.com",
      bg: "ddd1c3",
    },
  ]

  

  