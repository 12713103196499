import { configureStore } from "@reduxjs/toolkit";
import  userReducer  from "./userRedux";
import  registerReducer from "./registerRedux";
import { LoginReset } from "./userRedux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}
    
 const userpresistConfig ={
  key:'user',
  storage,
  whitelist: ['currentUser', 'accesstoken', 'refreshtoken', 'isLoggedIn'],
  blacklist:['error', 'isFetching']
 }
  
  
const persistedReducer = persistReducer(userpresistConfig, userReducer)

export const store  = 
configureStore({
    reducer:{
        user:persistedReducer,
        register:registerReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});


export const persistor = persistStore(store);