import styled, { keyframes } from "styled-components";
import { mobile } from "../responsive";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useState } from "react";
import dayjs from "dayjs";
import { publicRequest } from "../requestMethods";
import { CheckCircle } from "@material-ui/icons";
import { useEffect } from "react";

const Container = styled.div`
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    url("https://i.ibb.co/D5BRCw6/378058208-1462486214543864-3390851231272084783-n.jpg")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ width: "100%", display: "flex", flexDirection: "column" })}
`;

const Wrapper = styled.div`
  position: absolute;
  width: 15%;
  height: 60%;
  right: 100px;
  padding: 20px;
  /* border-radius: 6px; */
  border: 1px solid;
  border-color: yellow;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  box-shadow: 3px 2px 15px 5px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 3px 2px 15px 5px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 3px 2px 15px 5px rgba(0, 0, 0, 0.18);
  ${mobile({
    width: "60%",
    display: "flex",
    flexDirection: "column",
    height: "90%",
    right: "0",
    left: "15%",
  })}
`;

const Title = styled.h1`
  display: flex;

  font-size: 24px;
  font-weight: 1000;
  color: white;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  color: yellow;
`;

const Request = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;

  position: relative;
  top: 20%;
  /* left:30%; */
  align-items: center;
  /* justify-content: space-between; */
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;
const Sent = styled.div`
  display: flex;
  flex: auto;
  font-size: 25px;
  margin: 20px 10px;
  color: yellow;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background-color: yellow;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 1000;
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingCircle = styled.div`
  display: flex;
  /* margin-top: 40px; */
  width: 50px;
  height: 50px;
  border: 5px solid #baab1d;
  border-radius: 50%;
  border-top: 5px solid transparent;
  animation: ${spin} 1s linear infinite;
`;

const Contact = () => {
  const [firstname, SetFirstname] = useState("");
  const [lastname, SetLastname] = useState("");
  const [phonenumber, SetPhonenumber] = useState("");
  const [location, SetLocation] = useState("");
  const [destination, SetDestination] = useState("");
  const [date, SetDate] = useState(dayjs(new Date()));
  const [Error, setError] = useState("");
  const [isSent, setisSent] = useState(false);
  const [istracking, setistracking] = useState(false);
  const [Tripid, setTripId] = useState(null);
  const [isAccepted, setisAccepted] = useState(false);

  const formatDate = (formatdate) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      formatdate
    );

    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedTime = new Intl.DateTimeFormat("en-GB", optionsTime).format(
      formatdate
    );

    return `${formattedDate} ${formattedTime}`;
  };
  const formattedDateTime = formatDate(date);
  const handleRequest = async (e) => {
    e.preventDefault();

    let hasEmptyFields = false;
    if (firstname === "") {
      setError("Firstname is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }
    if (lastname === "") {
      setError("Lastname is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }
    if (phonenumber === "") {
      setError("Phone Number is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }
    if (location === "") {
      setError("location is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }
    if (destination === "") {
      setError("destination is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }
    if (formattedDateTime === null) {
      setError("Date/Time is required.");
      hasEmptyFields = true;
    } else {
      setError("");
    }

    if (hasEmptyFields) {
      return; // Don't proceed if there are empty fields
    }
    if (Error) {
      return;
    }
    try {
      const reqparams = {
        firstname,
        lastname,
        phonenumber,
        location,
        destination,
        date,
      };

      const response = await publicRequest.post(
        "/triprequest/newrequest",
        reqparams
      );
      if (response.status === 201) {
        if (response.data) {
          setisSent(true);
          // console.log()
          setTripId(response.data.tripId);
          // console.log("done");
        } else {
          console.error("Response data is empty");
        }

        // Dispatch the UpdatePasswordSuccess action to update the user's password

        // Optionally, you can inform the user that the password has been successfully updated here.
      } else {
        console.log("test1");

        // Handle other error scenarios as needed.
      }
    } catch (error) {
      console.log("test2");
    }
  };
  useEffect(() => {
    if (istracking) {
      const intervalId = setInterval(handletracking, 5000);

      // Cleanup function to clear the interval when the component unmounts or tracking is stopped
      return () => clearInterval(intervalId);
    }
  }, [istracking]);
  const handletracking = async () => {
    setistracking(true);
    console.log(typeof Tripid);
    try {
      const res = await publicRequest.get(
        `/triprequest/trackrequest/${Tripid}`
      );
      console.log(res.data.isAccepted);
      const Accepted = res.data.isAccepted;
      if (Accepted === true) {
        setisAccepted(true);
        setistracking(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Wrapper>
        {!isSent ? (
          <>
            <Title>Request Driver</Title>
            <Form>
              <Input
                placeholder="name"
                onChange={(e) => SetFirstname(e.target.value)}
              />
              <Input
                placeholder="last name"
                onChange={(e) => SetLastname(e.target.value)}
              />

              <Input
                placeholder="phone number"
                type="number"
                onChange={(e) => SetPhonenumber(e.target.value)}
              />
              <Input
                placeholder="location"
                onChange={(e) => SetLocation(e.target.value)}
              />
              <Input
                placeholder="destination"
                onChange={(e) => SetDestination(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Choose Date"
                  disableUnderline
                  value={date}
                  onChange={(newDate) => SetDate(newDate)}
                  sx={{
                    width: "100%",
                    "& .MuiIconButton-root": {
                      // margin: '20px 10px 0px 0px',
                      color: "white",
                    },
                    "& .MuiInputLabel-root": {
                      margin: "15px 10px 0px 0px",
                      // "&:hover > fieldset": { margin: '15px 10px 0px 0px' },

                      color: "#e3e3e3",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      height: "48px",
                      color: "#e3e3e3",
                      margin: "20px 10px 0px 0px",
                    }, //styles the label
                    "& .MuiOutlinedInput-root": {
                      "&:hover > fieldset": { borderColor: "white" },
                      height: "48px",
                      borderRadius: "6px",
                      color: "#e3e3e3",
                      margin: "20px 10px 0px 0px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                ></DateTimePicker>
              </LocalizationProvider>

              <Button onClick={handleRequest}>REQUEST</Button>
              <Agreement>
                By creating an account, I consent to the processing of my
                personal data in accordance with the <b>PRIVACY POLICY</b>
              </Agreement>
            </Form>
          </>
        ) : (
          <>
            <Title>Request Driver</Title>
            <Request>
              {istracking === false && isAccepted === false ? (
                <>
                  <Sent>
                    <CheckCircle
                      style={{
                        fontSize: "80px",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </Sent>
                  <Sent>Request Sent Successfully</Sent>
                </>
              ) : istracking === true ? (
                <>
                  <Sent>
                    <LoadingCircle></LoadingCircle>
                  </Sent>
                  <Sent>Waiting For Driver</Sent>
                </>
              ) : istracking === false && isAccepted === true ? (
                <>
                  {/* Additional condition for accepted */}
                  <Sent>
                    <CheckCircle
                      style={{
                        fontSize: "80px",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </Sent>
                  <Sent>Request Accepted</Sent>
                </>
              ) : (
                <>
                  {/* Additional condition for other cases */}
                  <Sent>
                    <CheckCircle
                      style={{
                        fontSize: "80px",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </Sent>
                  <Sent>Error</Sent>
                </>
              )}
              {!istracking && !isAccepted && (
                <Button onClick={handletracking}>Track Request</Button>
              )}
              <Button>Cancel Request</Button>
            </Request>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default Contact;
