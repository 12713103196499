import styled from "styled-components";
import {mobile} from "../responsive";
import {React, useState} from "react";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
// import {publicRequest} from "../requestMethods"

import {
  
  Link
} from "react-router-dom";
import { login } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
const Container = styled.div`
  max-width:100%;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.0),
      rgba(255, 255, 255, 0.0)
    ),
    url("https://i.ibb.co/D5BRCw6/378058208-1462486214543864-3390851231272084783-n.jpg")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  place-content:center;
`;

const Wrapper = styled.div`
position: absolute;


  width: 30%;
  height:40%;
  
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  ${mobile({ width: "60%", display:'flex', flexDirection:'column' })}
`;

const Title = styled.h1`
  display: flex;
  font-size: 24px;
  font-weight: 1000;
  color:white;
  align-items: center;
  justify-content: center; 
`;

const Form = styled.form`
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  color:yellow;
`;

const Input = styled.input`
flex: 1;
min-width: 40%;
margin: 20px 10px 0px 0px;
padding: 10px;
border-radius: 6px;
  
`;

const Button = styled.button`
 

 
  background-color: yellow;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 1000;
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
  &:disabled{
    background-color: red;
    cursor: not-allowed;
  };

`;
const Error = styled.span`
  color : red;
`;
const LinkStyle = styled(Link)`
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color:yellow;
`;

const Login = () => {
 const [username,SetUsername] = useState("");
 
 const [password,SetPassword] = useState("");
 const dispatch = useDispatch();
 const {isFetching, error} = useSelector((state) => state.user);

 const handlelogin = (e) =>{
  e.preventDefault()
  console.log('done')
  login(dispatch, {username, password})
  
 }
  return (
      <><Announcement /><Navbar /><Container>

      <Wrapper>
        <Title>SIGN IN</Title>
        <Form>
          <Input placeholder="username" onChange={(e)=> SetUsername(e.target.value)} />
          <Input placeholder="password" type="password" onChange={(e)=> SetPassword(e.target.value)} />
          
          <Button onClick={handlelogin} disabled={isFetching}>LOGIN</Button>
          {error && <Error>Wrong Username or Password</Error>}
          <LinkStyle>Forgot password?</LinkStyle>
          <LinkStyle to={`/register`}>CREATE A NEW ACCOUNT</LinkStyle>
        </Form>
      </Wrapper>
    </Container><Footer /></>
  );
};

export default Login;

