import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { store, persistor } from "./redux/store";
import { UpdateTokenSuccess } from "./redux/userRedux";

const Base_URL = "https://vmi1581909.contaboserver.net:5000/api";

const RefreshToken = async (dispatch) => {
  let refreshtokenString = JSON.parse(
    localStorage.getItem("persist:user")
  ).refreshtoken;

  try {
    const res = await axios.post("https://vmi1581909.contaboserver.net:5000/api/auth/refresh", {
      token: refreshtokenString,
    });

    const newAccessToken = res.data.accesstoken;
    const newRefreshToken = res.data.refreshtoken;

    dispatch(
      UpdateTokenSuccess({
        accesstoken: newAccessToken,
        refreshtoken: newRefreshToken,
      })
    );

    persistor.persist();
    return res.data;
  } catch (err) {
    console.log("Token refresh failed:", err);
    throw err;
  }
};

export const publicRequest = axios.create({
  baseURL: Base_URL,
});
// console.log('Headers on client side:', { token: `Bearer ${TOKEN}` });
export const userRequest = axios.create({
  baseURL: Base_URL,
});

userRequest.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    let TOKEN = JSON.parse(localStorage.getItem("persist:user")).accesstoken;
    const decodedToken = jwtDecode(TOKEN);
    if (TOKEN) {
      config.headers.token = `Bearer ${TOKEN}`;
      config.headers["authorization"] = "Bearer " + TOKEN;
    }
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      const data = await RefreshToken(store.dispatch);
      config.headers["authorization"] = "Bearer " + data.accesstoken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
