import userRedux, {
  LoginFailed,
  LoginReset,
  LoginStart,
  LoginSuccess,
} from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import {
  RegisterFailed,
  RegisterStart,
  RegisterSuccess,
} from "./registerRedux";
import { persistor } from "./store";

export const login = async (dispatch, user) => {
  dispatch(LoginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    const userData = {
      user: res.data.user,
      accesstoken: res.data.accesstoken,
      refreshtoken: res.data.refreshtoken,
    };
    dispatch(LoginSuccess(userData));
    persistor.persist();
  } catch (err) {
    dispatch(LoginFailed());
    console.log(err.response);
  }
};
export const register = async (dispatch, newuser) => {
  dispatch(RegisterStart());

  try {
    const res = await publicRequest.post("/auth/register", newuser);
    dispatch(RegisterSuccess(res.data));
   
    dispatch(LoginSuccess(res.data));
  } catch (error) {
    dispatch(RegisterFailed());
    
  }
};

export const logout = async (dispatch, refreshtoken) => {
  try {
    await userRequest.post("auth/logout", {
      token: refreshtoken,
    });
    dispatch(LoginReset());
    persistor.purge();
  } catch (error) {
    console.log(error);
  }
};
