/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobile } from "../responsive";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ display: "none" })}
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #${(props) => props.bg};
`;

const ImgContainer = styled.div`
  display: flex;
  height: 100%;
  /* margin-top: 50px;
  margin-left: 50px; */
  flex: 2;
  justify-content: center;
  align-items: center;
  /* background-color: green; */
`;

const Image = styled.img`
  border-radius: 50%;
  margin-left: 50px;
  height: 500px;
  width:500px;
`;

const InfoContainer = styled.div`
  flex: 2;
  padding: 50px 0 50px 50px;
  max-width: 50%;
  position: relative;
  right:90px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 8px 9px 20px -4px rgba(0,0,0,0.75);
-webkit-box-shadow: 8px 9px 20px -4px rgba(0,0,0,0.75);
-moz-box-shadow: 8px 9px 20px -4px rgba(0,0,0,0.75);
  
`;

const Title = styled.h1`
  font-size: 70px;
`;
const Phone = styled.div`
  font-size: 30px;
`;
const Desc = styled.div`
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  /* letter-spacing: 3px; */
`;

// const Button = styled.button`
//   padding: 10px;
//   font-size: 20px;
//   background-color: transparent;
//   cursor: pointer;
// `;

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 1);
    } else {
      setSlideIndex(slideIndex < 1 ? slideIndex + 1 : 0);
    }
  };
  useEffect(() => {
    // Set up an interval to call handleClick every 20 seconds
    const intervalId = setInterval(() => {
      handleClick(/* specify your default direction here */);
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [slideIndex]);

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {sliderItems.map((item) => (
          <Slide bg={item.bg} key={item.id}>
            <ImgContainer>
              <Image src={item.img} />
            </ImgContainer>
            <InfoContainer>
              <Title> <Phone>Phone Number: </Phone>{item.title}</Title>
              <Desc><Phone>Email: </Phone> {item.desc}</Desc>
              {/* <Button>SHOW NOW</Button> */}
            </InfoContainer>
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

export default Slider;
