import React, { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import CountryFlag from 'react-country-flag';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/apiCalls";

const Container = styled.div`
  height: 60px;
  max-width: 100%;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: background-color 5s, box-shadow 0.3s; 

  ${mobile({ height: "100%", width: "100%" })}
`;

const Wrapper = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mobile({ padding: "10px 0px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  
  
`;

const Home = styled.span`
display: inline-flex;
align-items: center;
/* justify-content:center; */
  height:43px;
  max-height: 50px;
  color:black;
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
  padding: 5px;
  text-decoration:none;
  /* background-color: #cd3d3d; */
  &:hover {
    background-color: #0e0d0d; /* Change the background color on hover */
    color:white;
    border-bottom: 7px solid yellow;
  }
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;

const Logo = styled.h1`
  font-weight: bold;
  position: relative;
  left: 40px;
  ${mobile({ fontSize: "15px" })}
`;
const Right = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: right;
  justify-content: flex-end;

  ${mobile({
    flex: 2,
    justifyContent: "right",
    position: "relative",
    flexDirection: "raw",
    marginRight: "10px",
  })}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;

  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;
const DropdownMenu = styled.div`
  background: #000000 none repeat scroll 0 0;
  border-radius: 0;
  border: 2px solid yellow;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  display: none;
  margin: 0;
  padding: 25px 25px;
  position: absolute;
  top: 98%;
  left: 65%;
  z-index: 0;
  width: 200px;
  ${mobile({ fontSize: "12px", left: "8%", width: "50%", top: "100%" })};
`;
const MenuItemuser = styled.div`
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  &:hover ${DropdownMenu} {
    display: block;
  }
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const DropdownMenuItem = styled.ul`
  color: yellow;
  margin-bottom: 20px;
  border-bottom: 2px solid grey;
`;
const Button = styled.button`
  padding: 10px;
  background-color: #ffffff;
 
  color: #000000;
  border: none;
  cursor: pointer;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 43px;
  background-color: #a5a3a3;
  min-width: 50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  border: 1px solid;
  border-color: yellow;
 

  ${mobile({ right: "15px"})};
 
  
`;

const Option = styled.a`
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #0b0b0b;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;

  &:hover {
    background-color: #6b6b6b;
  }
`;
const Navbar = () => {
  const user = useSelector((state) => state.user.currentUser);
  const refreshToken = useSelector((state) => state.user.refreshtoken);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const dispatch = useDispatch();
  const handlelogout = () => {
    console.log(refreshToken);
    logout(dispatch, refreshToken);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("DE");
  const [selectedCode, setSelectedCode] = useState("CH");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  
    if (language === "EN") {
      setSelectedCode("GB")
    } else if (language === "FR") {
      setSelectedCode("FR")
    }else{
      setSelectedCode("CH")
    }
    setIsOpen(false);
    // You can add logic here to handle language change
  };
  return (
    <Container>
      <Wrapper>
        <Left>
          <Link style={{ textDecoration: "none" }} to={`/`}>
            <Home>Home</Home>
            <Home>Prices</Home>
            <Home>Contact</Home>
            <Home>About us</Home>

          </Link>
        </Left>
        <Center>
          <Logo>Taxi Imed</Logo>
        </Center>
        <Right>
          <Button onClick={() => setIsOpen(!isOpen)}><CountryFlag countryCode={selectedCode} svg /> / {selectedLanguage}</Button>
          <DropdownContent open={isOpen}>
            <Option href="#" onClick={() => handleLanguageChange("EN")}>
            <CountryFlag countryCode="GB" svg /> / EN
            </Option>
            <Option href="#" onClick={() => handleLanguageChange("FR")}>
            <CountryFlag countryCode="FR" svg /> / FR
            </Option>
            <Option href="#" onClick={() => handleLanguageChange("DE")}>
            <CountryFlag countryCode="CH" svg /> / DE
            </Option>
          </DropdownContent>
          {!isLoggedIn ? (
            <>
              <Link style={{ textDecoration: "none" }} to={`/register`}>
                <MenuItem></MenuItem>
              </Link>
              <Link style={{ textDecoration: "none" }} to={`/login`}>
                <MenuItem></MenuItem>
              </Link>
            </>
          ) : (
            <>
              <MenuItemuser>
                {user?.username}
                <DropdownMenu>
                  <DropdownMenuItem>User ID: {user?.userId}</DropdownMenuItem>
                  <Link style={{ textDecoration: "none" }} to={`/profile`}>
                    <DropdownMenuItem>Profile</DropdownMenuItem>
                  </Link>
                  {/* Dropdown content goes here */}

                  <DropdownMenuItem onClick={handlelogout}>
                    Log Out
                  </DropdownMenuItem>
                </DropdownMenu>
              </MenuItemuser>
            </>
          )}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
