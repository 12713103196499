import styled from "styled-components";
import { mobile } from "../responsive";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {React, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../redux/apiCalls";
const Container = styled.div`
  max-width:100%;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.0),
      rgba(255, 255, 255, 0.0)
    ),
    url("https://i.ibb.co/D5BRCw6/378058208-1462486214543864-3390851231272084783-n.jpg")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ width: "100%", display:'flex', flexDirection:'column' })}
`;

const Wrapper = styled.div`
position: absolute;
  width: 15%;
  height:60%;
  right:100px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  ${mobile({width:'60%',display:'flex' , flexDirection:'column',height:'90%', right:'0', left:'15%'})}
`;

const Title = styled.h1`
  display: flex;
  font-size: 24px;
  font-weight: 1000;
  color:white;
  align-items: center;
  justify-content: center; 
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  color:yellow;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
  
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Button = styled.button`
 

 
  background-color: yellow;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 1000;
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
  &:disabled{
    background-color: red;
    cursor: not-allowed;
  };
`;
const Error = styled.span`
  color : red;
`;




const Register = () => {
  const [firstname, SetFirstname] = useState("");
  const [lastname, SetLastname] = useState("");
  const [username, SetUsername] = useState("");
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [passwordConfirmation, SetPasswordConfirmation] = useState("");
  const dispatch = useDispatch();
  const { isSending, error } = useSelector((state) => state.user);

  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");

  const MIN_PASSWORD_LENGTH = 4; // Minimum password length

  const handleEmailInput = (e) => {
    const emailValue = e.target.value;
    SetEmail(emailValue);

    if (!isValidEmail(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFirstnameInput = (e) => {
    const firstnameValue = e.target.value;
    SetFirstname(firstnameValue);

    if (firstnameValue.length === 0) {
      setFirstnameError("First name is required.");
    } else {
      setFirstnameError("");
    }
  };

  const handleLastnameInput = (e) => {
    const lastnameValue = e.target.value;
    SetLastname(lastnameValue);

    if (lastnameValue.length === 0) {
      setLastnameError("Last name is required.");
    } else {
      setLastnameError("");
    }
  };

  const handleUsernameInput = (e) => {
    const usernameValue = e.target.value;
    SetUsername(usernameValue);

    if (usernameValue.length < 3) {
      setUsernameError("Username must be at least 3 characters.");
    } else {
      setUsernameError("");
    }
  };

  const handlePasswordInput = (e) => {
    const passwordValue = e.target.value;
    SetPassword(passwordValue);

    if (passwordValue.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters.`);
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordConfirmationInput = (e) => {
    const passwordConfirmationValue = e.target.value;
    SetPasswordConfirmation(passwordConfirmationValue);

    if (passwordConfirmationValue !== password) {
      setPasswordConfirmationError("Passwords do not match.");
    } else {
      setPasswordConfirmationError("");
    }
  };

  const isValidEmail = (email) => {
    // A basic email validation using a regular expression
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleRegister = (e) => {
    e.preventDefault();
  
    let hasEmptyFields = false;
  
    if (email === "") {
      setEmailError("Email is required.");
      hasEmptyFields = true;
    } else {
      setEmailError("");
    }
  
    if (firstname === "") {
      setFirstnameError("First name is required.");
      hasEmptyFields = true;
    } else {
      setFirstnameError("");
    }
  
    if (lastname === "") {
      setLastnameError("Last name is required.");
      hasEmptyFields = true;
    } else {
      setLastnameError("");
    }
  
    if (username === "") {
      setUsernameError("Username is required.");
      hasEmptyFields = true;
    } else {
      setUsernameError("");
    }
  
    if (password === "") {
      setPasswordError("Password is required.");
      hasEmptyFields = true;
    } else {
      setPasswordError("");
    }
  
    if (passwordConfirmation === "") {
      setPasswordConfirmationError("Confirm password is required.");
      hasEmptyFields = true;
    } else {
      setPasswordConfirmationError("");
    }
  
    if (hasEmptyFields) {
      return; // Don't proceed if there are empty fields
    }
  
    if (
      emailError ||
      firstnameError ||
      lastnameError ||
      usernameError ||
      passwordError ||
      passwordConfirmationError
    ) {
      return;
    }
  
    // Ensure password and password confirmation match
    if (password !== passwordConfirmation) {
      setPasswordConfirmationError("Passwords do not match.");
      return;
    }
    console.log('tests')
    // Proceed with registration
    register(dispatch, { firstname, lastname, username, email, password });
  };
  

  return (
    <>
      <Announcement />
      <Navbar />
      <Container>
        <Wrapper>
          <Title>CREATE AN ACCOUNT</Title>
          <Form>
          <Input
              placeholder={firstnameError || "name"}
              onChange={handleFirstnameInput}
              required
            />
            <Input
              placeholder={lastnameError || "last name"}
              onChange={handleLastnameInput}
              required
            />
            <Input
              placeholder={usernameError || "username"}
              onChange={handleUsernameInput}
              required
            />
            <Input
              placeholder={emailError || "email"}
              onChange={handleEmailInput}
              required
              value={email}
            />
            {emailError && <Error>{emailError}</Error>}
            <Input
              placeholder={passwordError || "password"}
              type="password"
              onChange={handlePasswordInput}
              required
            />
             {passwordError && <Error>{passwordError}</Error>}
            <Input
              placeholder={passwordConfirmationError || "confirm password"}
              type="password"
              onChange={handlePasswordConfirmationInput}
              required
            />
            {passwordConfirmationError && (
              <Error>{passwordConfirmationError}</Error>
            )}
            <Agreement>
              By creating an account, I consent to the processing of my personal
              data in accordance with the <b>PRIVACY POLICY</b>
            </Agreement>
            <Button onClick={handleRegister} disabled={isSending}>
              CREATE
            </Button>
            {error && <Error>Something Went Wrong</Error>}
          </Form>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};



export default Register;
