import {createSlice} from '@reduxjs/toolkit';

const registerSlice = createSlice({
    name:"newuser",
    initialState:{
        
        isSending:false,
        error:false
    },
    reducers:{
        RegisterStart:(state) => {
            state.isSending=true
        },
        RegisterSuccess:(state,action) => {
            state.isSending=false;
            state.newuser=action.payload
        },
        RegisterFailed:(state) => {
            state.isSending=false;
            state.error=true
        },
    },
});
export const {RegisterStart, RegisterSuccess, RegisterFailed} = registerSlice.actions;
export default registerSlice.reducer;