import React, { useState } from "react";
import styled from "styled-components";
import {mobile} from "../responsive";
import Announcement from "../components/Announcement";

import Navbar from "../components/Navbar";
import { Close, Edit } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { userRequest } from "../requestMethods";
import { UpdatePasswordSuccess } from "../redux/userRedux";
const CryptoJS = require('crypto-js');
const PopContainer = styled.div`
 position:fixed;
 padding-top:100px;
 max-height:1080px;
  max-width:100%;
  width: 100vw;
  height: 800px;

  display: flex;
  align-items: center;
  justify-content: center;
  place-content:center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:1;
  ${mobile({ height: "100%" })}
`;
const Container = styled.div`
position:fixed;
  height: 60px;
  z-index:-1;

  ${mobile({ height: "50px" })}
`;
const Sidebar = styled.div`
 position: relative;

 width:300px;
 height:860px;
 top:0;
 
 ${mobile({width:'70%' })}
`;
const SidebarItems = styled.ul`
  position:relative;
  padding-top: 20px;
  margin-top:10px;
  cursor:pointer;
  text-align:left;
  

 
  top:0;
  &:hover {
    background-color:rgba(0, 0, 0, 0.15);
    border-left : 5px solid 
  };
  
`;
const SidebarAccount = styled.li`
  position:relative;
  list-style-type: none;
  bottom:10px;
  right:20px;
  font-size:20px;
  text-align:center;
  font-weight: 600;
  
`;
const SidebarSec = styled.li`
  position:relative;
  list-style-type: none;
  bottom:10px;
  right:20px;
  font-size:20px;
  text-align:center;
  font-weight: 600;
  
`;
const AccInfo = styled.div`
  background-color:transparent; 
  color:black;
  position:relative;
  width:700px;
  max-height:875px;
  bottom:870px;
  left:300px;
  border-bottom:1px solid black;

  ${mobile({width:'100%', left:'70%' , flexDirection:'column' })}
  
  
`;
const AccTitle = styled.div`
  padding-top: 20px;
  padding-left:20px;
  background-color:transparent; 
  color:black;
  font-size:40px;
  
  font-weight: 600;
  

  
  
  
`;
const BasicInfo = styled.li`
  position:relative;
  list-style-type: none;
  
  color:black;
  font-size:20px;
 
  font-weight: 600;
  padding-top: 30px;
 
  
  text-align:left;
  border-bottom:1px solid black;
  
padding-left:20px;
padding-bottom:20px;
 
 
  &:hover {
    background-color:rgba(0, 0, 0, 0.05);
   
  };

`;
const Info = styled.span`
font-size:10px;
flex: 1;
display: flex;

padding-top:10px;
padding-left:20px;
color:black;
`;
const Sec = styled.div`
background-color:transparent; 
  color:black;
  position:relative;
  width:700px;
  max-height:875px;
  bottom:870px;
  left:300px;
  border-bottom:1px solid black;
  ${mobile({width:'115%', left:'70%' , flexDirection:'column' })}
`;
const Wrapper = styled.div`
position: absolute;


  width: 30%;
  height:40%;
  top:25%;
  left:30%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  ${mobile({ width: "60%", display:'flex',left:'15%' , flexDirection:'column' })}
`;

const Title = styled.h1`
  display: flex;
  font-size: 24px;
  font-weight: 1000;
  color:white;
  align-items: center;
  justify-content: center; 
`;

const Form = styled.form`
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  color:yellow;
`;

const Input = styled.input`
flex: 1;
min-width: 40%;
margin: 20px 10px 0px 0px;
padding: 10px;
border-radius: 6px;
  
`;

const Button = styled.button`
 

 
  background-color: yellow;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 1000;
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  border-radius: 6px;
  &:disabled{
    background-color: red;
    cursor: not-allowed;
  };
`;
const Error = styled.span`
  color : red;
`;
const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const [isinfo, SetIsInfo] = useState(true);
  const [issec, SetIsSec] = useState(false);
  const [changepass, SetChangePass] = useState(false);
  const [oldPass, SetOldPass] = useState("");
  const [newpassword, SetPassword] = useState("");
  const [passwordConfirmation, SetPasswordConfirmation] = useState("");
  const [oldPassError, SetOldPassError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const sectioninfohandler = () => {
    
     
     
        SetIsInfo(true);
        SetIsSec(false);
      
      
      
  };
  const sectionsechandler = () => {
   
    // SetIsSec(false);
    
      SetIsInfo(false);
      SetIsSec(true);
  
    
    
  };
  const passwordchange = () => {
   
    // SetIsSec(false);
    
    SetChangePass(true);
    
    
  };
  const passwordchangeclose = () => {
   
    // SetIsSec(false);
    
    SetChangePass(false);
    
    
  };
  const handlePasswordInput = (e) => {
    const passwordValue = e.target.value;
    SetPassword(passwordValue);
    const MIN_PASSWORD_LENGTH = 3; // Minimum password length
    if (passwordValue.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters.`);
    } else {
      setPasswordError("");
    }
    if (oldPass === passwordValue) {
      setPasswordError("try Diffrent PassWord");
      return;
    }
  };
  const handlePasswordConfirmationInput = (e) => {
    const passwordConfirmationValue = e.target.value;
    SetPasswordConfirmation(passwordConfirmationValue);

    if (passwordConfirmationValue !== newpassword) {
      setPasswordConfirmationError("Passwords do not match.");
    } else {
      setPasswordConfirmationError("");
    }
  };
  const oldpasshandler = (e) => {
    
    const oldpasswordValue = e.target.value;
    SetOldPass(oldpasswordValue);
    // Assuming 'user?.password' contains the encrypted password
    const ciphertext = user?.password;
   
    // Provide the secret key used for encryption
    const secretKey = 'Uncle-_-Joe';
  
    try {
      // Decrypt the password using the provided key
      const bytes  = CryptoJS.AES.decrypt(ciphertext, secretKey);
  
      // Convert the decrypted bytes to a string
      const passwordDecrypt = bytes.toString(CryptoJS.enc.Utf8);
  
      // Compare the decrypted password with the 'oldPass' value
     
      if (oldpasswordValue !== passwordDecrypt) {
        
        SetOldPassError("Wrong Old Password");
        
      } else {
      
        
        
        SetOldPassError("")
       
      }
    } catch (error) {
      console.error('Error during decryption:', error);
    }
  };
  const updatepass = async (e) => {
    e.preventDefault();
    
    let hasEmptyFields = false;
    if (oldPass === "") {
      setPasswordError("Old Password is required.");
      hasEmptyFields = true;
    } else {
      setPasswordError("");
    }
    if (newpassword === "") {
      setPasswordError("Password is required.");
      hasEmptyFields = true;
    } else {
      setPasswordError("");
    }
  
    if (passwordConfirmation === "") {
      setPasswordConfirmationError("Confirm password is required.");
      hasEmptyFields = true;
    } else {
      setPasswordConfirmationError("");
    }
  
    if (hasEmptyFields) {
      return; // Don't proceed if there are empty fields
    }
  
    if (
      
      oldPassError ||
      passwordError ||
      passwordConfirmationError
    ) {
      return;
    }
    if (oldPass === newpassword) {
      setPasswordError("try Diffrent PassWord");
      return;
    }
    // Ensure password and password confirmation match
    if (newpassword !== passwordConfirmation) {
      setPasswordConfirmationError("Passwords do not match.");
      return;
    }
  
    try {
      const usercheck = user?.username;
      const changeparams = { usercheck, oldPass, newpassword };
      // console.log(userRequest.headers)
      const response = await userRequest.post("/auth/changepass", changeparams);
      if (response.status === 201) {
        if (response.data) {
          const updatedUser = await response.data;
          dispatch(UpdatePasswordSuccess(updatedUser));
          passwordchangeclose()
          console.log('test')
        } else {
          console.error('Response data is empty');
        }
        
  
        // Dispatch the UpdatePasswordSuccess action to update the user's password
       
       
        // Optionally, you can inform the user that the password has been successfully updated here.
      } else {
        console.log('test1')
        setPasswordConfirmationError("Something not 201");
        // Handle other error scenarios as needed.
      }
    } catch (error) {
      console.log('test2')
      setPasswordConfirmationError("Something Went Wrong");
    }
    
  };


  return (

    <><Announcement /><Navbar />
    <Container>
      <Sidebar>
        <SidebarItems onClick={sectioninfohandler} ><SidebarAccount>Account Informations</SidebarAccount>
        
        </SidebarItems>
        <SidebarItems onClick={sectionsechandler} ><SidebarSec >Security</SidebarSec></SidebarItems>
      </Sidebar>
      {isinfo && <AccInfo> <AccTitle>Account Info</AccTitle>
      <BasicInfo>Name:<Info>{user?.firstname} {user?.lastname} <Edit fontSize="small" style={{display:'flex',flexDirection:'row',flex:1, position:'fixed', top:'209px',left:'960px' , color:'black',float:'right',cursor:'pointer'}} /></Info></BasicInfo>
      <BasicInfo>Email:<Info>{user?.email}  <Edit fontSize="small" style={{display:'flex',flexDirection:'row',flex:1, position:'fixed', top:'310px',left:'960px' , color:'black',float:'right',cursor:'pointer'}} /></Info></BasicInfo>
       </AccInfo> }
       {issec && <Sec>
        <AccTitle>Security</AccTitle>
        <BasicInfo  onClick={passwordchange}>Password:<Info>*************<Edit onClick={passwordchange} fontSize="small" style={{display:'flex',flexDirection:'row',flex:1, position:'fixed', top:'209px',left:'960px' , color:'black',float:'right',cursor:'pointer'}} /></Info></BasicInfo>
      
       </Sec> }
      

    </Container>
    {changepass &&  <PopContainer><Wrapper>
        <Title>Change Password</Title>
        <Close onClick={passwordchangeclose} style={{position:'absolute', right:'5px',top:'5px', cursor:'pointer'}}></Close>
        <Form>
        <Input  placeholder={oldPassError || "old password"} type="password" onChange={oldpasshandler} />
        {oldPassError && (
              <Error>{oldPassError}</Error>
            )}
          <Input  placeholder={passwordError || "password"} type="password"  onChange={handlePasswordInput} />
          {passwordError && (
              <Error>{passwordError}</Error>
            )}
          <Input placeholder={passwordConfirmationError ||"Confirm password"} type="password"  onChange={handlePasswordConfirmationInput}/>
          {passwordConfirmationError && (
              <Error>{passwordConfirmationError}</Error>
            )}
          <Button onClick={updatepass}>Submit</Button>
          
          
        </Form>
      </Wrapper></PopContainer>}
    </>
  );   
};

export default Profile;