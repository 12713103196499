
import Home from "./pages/Home";

import Register from "./pages/Register";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
  
} from "react-router-dom";
import { useSelector } from "react-redux";



const App = () => {
  const user = useSelector((state) => state.user.currentUser)
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        
        <Route path="/register" element={
          user ? (
            <Navigate replace to="/" />
          ) : (
        <Register/>)
      } />
        <Route path="/login" element={
            user ? (
              <Navigate replace to="/" />
            ) : (
              <Login />
            )
          } />
          <Route  path="/profile" element={
            !user ? (
              <Navigate replace to="/" />
            ) : (
              <Profile />
            )
          } />
      </Routes>
    </Router>
  );
};

export default App;